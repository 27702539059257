import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import ContentUsagePrerequisiteListSection from "@/content-usage/ContentUsagePrerequisiteListSection"
import { ContentUsagePrerequisiteListQuery } from "@/content-usage/__generated__/ContentUsagePrerequisiteListQuery.graphql"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import ContentUsagePrerequisiteItem from "@/content-usage/modules/actions/ContentUsagePrerequisiteItem"
import UpdateContentModuleButton from "@/content-usage/modules/actions/UpdateContentModuleButton"
import ContentModuleCompletedUsersAvatarStack from "@/content-usage/modules/components/ContentModuleCompletedUsersAvatarStack"
import ContentUtils from "@/content/util/contentUtils"
import { useLabel } from "@/core/context/LabelsContext"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoButton,
  DiscoIcon,
  DiscoIconButton,
  DiscoModal,
  DiscoSection,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import { range } from "@utils/array/arrayUtils"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useLazyLoadQuery } from "react-relay"

interface ContentUsagePrerequisiteListProps extends TestIDProps {
  openPrerequisites: () => void
  closePrerequisitesModal: () => void
  isOpen: boolean
  contentUsageId: GlobalID
  hideFooter?: boolean
}

function ContentUsagePrerequisiteList({
  closePrerequisitesModal,
  isOpen,
  contentUsageId,
  hideFooter = false,
}: ContentUsagePrerequisiteListProps) {
  const drawer = useContentUsageDrawer()
  const { usage } = useLazyLoadQuery<ContentUsagePrerequisiteListQuery>(
    graphql`
      query ContentUsagePrerequisiteListQuery($id: ID!) {
        usage: node(id: $id) {
          ... on ContentUsage {
            id
            entity
            product {
              ...usePermissionsFragment
            }
            content {
              name
              type
              label
              isCurriculumModule
            }
            module {
              name
              usages {
                edges {
                  node {
                    id
                    ...UpdateContentModuleButtonFragment
                    prerequisites {
                      edges {
                        node {
                          id
                          ...ContentUsagePrerequisiteItemFragment
                        }
                      }
                    }
                  }
                }
              }
            }
            prerequisites {
              edges {
                node {
                  id
                  ...ContentUsagePrerequisiteItemFragment
                }
              }
            }
            ...UpdateContentModuleButtonFragment
          }
        }
      }
    `,
    { id: contentUsageId }
  )
  const classes = useStyles()
  const permissions = usePermissions(usage?.product)
  const labels = useLabel("product_member")
  const contentLabel = ContentUtils.useContentLabel({
    content: {
      type: usage?.content?.type,
      label: usage?.content?.label || "",
    },
    entity: usage?.entity,
  })
  if (!usage) return null
  const contentModuleLabel = ContentModuleUtils.getContentModuleLabel(usage.entity!)

  const canManage = permissions.has("content.manage")

  const modulePrerequisitesUsages = Relay.connectionToArray(usage.module?.usages)
  const moduleUsage = modulePrerequisitesUsages[0]
  const modulePrerequisites =
    modulePrerequisitesUsages.length > 0
      ? Relay.connectionToArray(moduleUsage.prerequisites)
      : []
  const itemPrerequisites = Relay.connectionToArray(usage.prerequisites)

  const hasModulePrerequisites = modulePrerequisites.length > 0
  const hasItemPrerequisites = itemPrerequisites.length > 0

  return (
    <DiscoModal
      isOpen={isOpen}
      onClose={closePrerequisitesModal}
      modalContentLabel={"ContentUsagePrerequisitesList"}
      testid={`ContentUsagePrerequisiteList.${usage.content?.name}`}
      title={renderTitle()}
      classes={{ body: classes.body }}
      subtitle={renderSubTitle()}
      hideFooter={hideFooter}
      buttons={
        <>
          {renderAdminButtons()}
          <DiscoButton onClick={closePrerequisitesModal}>{"Ok, got it"}</DiscoButton>
        </>
      }
      body={renderPrerequisites()}
    />
  )

  function renderSubTitle() {
    if (
      usage?.content?.isCurriculumModule ||
      (!hasItemPrerequisites && hasModulePrerequisites)
    ) {
      return canManage ? (
        <DiscoText>
          {`Within the ${contentModuleLabel} `}
          <DiscoText variant={"body-md-600"} component={"span"}>
            {usage?.module?.name || usage?.content?.name}
          </DiscoText>
          {`, ${labels.plural} are required to complete the following items before they can access the ${contentLabel}:`}
        </DiscoText>
      ) : (
        `You may only access this ${contentLabel} after the following prerequisites are completed:`
      )
    }

    return canManage ? (
      <DiscoText>
        {`This ${contentLabel} is a part of the ${contentModuleLabel} `}
        <DiscoText variant={"body-md-600"} component={"span"}>
          {usage?.module?.name}
        </DiscoText>
        {`. Members could only access this item when they complete:`}
      </DiscoText>
    ) : (
      <DiscoText>
        {`Within the ${contentModuleLabel} `}
        <DiscoText variant={"body-md-600"} component={"span"}>
          {usage?.module?.name}
        </DiscoText>
        {`, the following items are required before you can access the ${contentLabel}:`}
      </DiscoText>
    )
  }

  function renderTitle() {
    return canManage
      ? `This ${
          usage?.content?.isCurriculumModule ? contentLabel : contentModuleLabel
        } has prerequisites defined.`
      : `This ${contentLabel} is locked.`
  }

  function renderPrerequisites() {
    if (!usage) return null

    if (!hasModulePrerequisites) {
      return itemPrerequisites.map((p) => (
        <ContentUsagePrerequisiteItem
          key={p.id}
          contentModuleKey={p}
          hideCheckbox
          className={classes.prerequisiteItem}
          onClose={closePrerequisitesModal}
          rightTitleContent={
            canManage && <ContentModuleCompletedUsersAvatarStack contentUsageId={p.id} />
          }
        />
      ))
    }

    if (!hasItemPrerequisites) {
      return modulePrerequisites.map((p) => (
        <ContentUsagePrerequisiteItem
          key={p.id}
          contentModuleKey={p}
          hideCheckbox
          className={classes.prerequisiteItem}
          onClose={closePrerequisitesModal}
          rightTitleContent={
            canManage && <ContentModuleCompletedUsersAvatarStack contentUsageId={p.id} />
          }
        />
      ))
    }

    // If an item's module has prerequisites render the list of each prerequisite within collapsible sections
    const prerequisites = [
      <ContentUsagePrerequisiteListSection
        key={modulePrerequisitesUsages[0].id}
        testid={`${usage?.module?.name || usage?.content?.name}.prerequisites`}
        title={`${contentModuleLabel} Prerequisites`}
        subTitle={
          <DiscoText marginBottom={1.5} variant={"body-sm"}>
            {`This ${contentModuleLabel} `}
            <DiscoText variant={"body-sm-600"} component={"span"}>
              {usage?.module?.name}
            </DiscoText>
            {`, which the ${contentLabel} belongs to, has prerequisites of the following modules:`}
          </DiscoText>
        }
        rightIconButton={
          canManage && (
            <UpdateContentModuleButton contentUsageKey={moduleUsage}>
              {(btnProps) => (
                <DiscoIconButton
                  {...btnProps}
                  testid={`ContentUsageLockIcon.edit-module-button`}
                >
                  <DiscoIcon icon={"pencil"} />
                </DiscoIconButton>
              )}
            </UpdateContentModuleButton>
          )
        }
        body={modulePrerequisites.map((p) => (
          <ContentUsagePrerequisiteItem
            key={p.id}
            contentModuleKey={p}
            hideCheckbox
            className={classes.prerequisiteItem}
            onClose={closePrerequisitesModal}
            rightTitleContent={
              canManage && (
                <ContentModuleCompletedUsersAvatarStack contentUsageId={p.id} />
              )
            }
          />
        ))}
      />,
      <ContentUsagePrerequisiteListSection
        key={usage?.id}
        testid={`${usage?.content?.name}.prerequisites`}
        title={"Item Prerequisites"}
        subTitle={
          <DiscoText marginBottom={1.5} variant={"body-sm"}>
            {`Within the ${contentModuleLabel} `}
            <DiscoText variant={"body-sm-600"} component={"span"}>
              {usage?.module?.name}
            </DiscoText>
            {`, ${labels.plural} are required too complete the following items before they can access the ${contentLabel}:`}
          </DiscoText>
        }
        rightIconButton={
          canManage && (
            <DiscoIconButton
              onClick={openContentUsageItemPrerequisites}
              testid={`ContentUsageLockIcon.edit-module-button`}
            >
              <DiscoIcon icon={"pencil"} />
            </DiscoIconButton>
          )
        }
        body={itemPrerequisites.map((p) => (
          <ContentUsagePrerequisiteItem
            key={p.id}
            contentModuleKey={p}
            hideCheckbox
            className={classes.prerequisiteItem}
            onClose={closePrerequisitesModal}
            rightTitleContent={
              canManage && (
                <ContentModuleCompletedUsersAvatarStack contentUsageId={p.id} />
              )
            }
          />
        ))}
      />,
    ]

    return prerequisites
  }

  function openContentUsageItemPrerequisites() {
    if (!usage?.id) return

    closePrerequisitesModal()
    drawer.open({
      drawerContentUsageId: usage.id,
      drawerTab: "settings",
    })
  }

  function renderAdminButtons() {
    if (!canManage || (hasItemPrerequisites && hasModulePrerequisites)) return null

    return usage?.content?.isCurriculumModule ||
      (!hasItemPrerequisites && hasModulePrerequisites) ? (
      <UpdateContentModuleButton contentUsageKey={moduleUsage || usage}>
        {(btnProps) => (
          <DiscoButton
            {...btnProps}
            variant={"outlined"}
            color={"grey"}
            testid={`ContentUsageLockIcon.edit-module-button`}
          >
            {"Edit Prerequisite Settings"}
          </DiscoButton>
        )}
      </UpdateContentModuleButton>
    ) : (
      <DiscoButton
        onClick={openContentUsageItemPrerequisites}
        variant={"outlined"}
        color={"grey"}
      >
        {"Edit Prerequisite Settings"}
      </DiscoButton>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  prerequisiteItem: {
    padding: theme.spacing(1.5),
    borderRadius: theme.measure.borderRadius.medium,
    justifyContent: "flex-start",
    boxShadow: theme.palette.groovyDepths.insideCard,
    "&:hover": {
      backgroundColor: theme.palette.groovy.neutral[100],
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
}))

function ContentUsagePrerequisiteListSkeleton({
  isOpen,
  closePrerequisitesModal,
}: ContentUsagePrerequisiteListProps) {
  const classes = useStyles()

  return (
    <DiscoModal
      isOpen={isOpen}
      onClose={closePrerequisitesModal}
      modalContentLabel={"ContentUsagePrerequisitesList"}
      testid={`ContentUsagePrerequisiteListSkeleton`}
      title={<DiscoTextSkeleton width={150} />}
      classes={{ body: classes.body }}
      subtitle={<DiscoTextSkeleton width={250} marginBottom={2} />}
      minHeight={"300px"}
      body={range(3).map((idx) => (
        <DiscoSection key={idx} className={classes.prerequisiteItem}>
          <DiscoTextSkeleton width={100} />
        </DiscoSection>
      ))}
    />
  )
}

export default Relay.withSkeleton({
  component: ContentUsagePrerequisiteList,
  skeleton: ContentUsagePrerequisiteListSkeleton,
})
