import AddContentUsageButton from "@/content-usage/buttons/add-button/AddContentUsageButton"
import { CreateCollectionModuleItemContentUsageButtonFragment$key } from "@/content-usage/buttons/add-button/__generated__/CreateCollectionModuleItemContentUsageButtonFragment.graphql"
import { OverridableDiscoButtonChildren } from "@disco-ui/button/OverridableDiscoButton"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  children: OverridableDiscoButtonChildren
  moduleContentUsageKey: CreateCollectionModuleItemContentUsageButtonFragment$key
}

function CreateCollectionModuleItemContentUsageButton({
  testid = "CreateCollectionModuleItemContentUsageButton",
  children,
  moduleContentUsageKey,
}: Props) {
  const moduleContentUsage =
    useFragment<CreateCollectionModuleItemContentUsageButtonFragment$key>(
      graphql`
        fragment CreateCollectionModuleItemContentUsageButtonFragment on ContentUsage {
          id
          content {
            id
          }
          productId
        }
      `,
      moduleContentUsageKey
    )

  return (
    <AddContentUsageButton
      testid={testid}
      productId={moduleContentUsage.productId}
      contentUsageInput={{
        entity: "content",
        entityId: moduleContentUsage.content.id,
        ordering: 0, // Always add to the start of the collection module
      }}
      addingTo={"collection"}
      moduleContentUsageId={moduleContentUsage.id}
    >
      {children}
    </AddContentUsageButton>
  )
}

export default CreateCollectionModuleItemContentUsageButton
