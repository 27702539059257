import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import {
  ContentModulesDragDropProvider_UpdateContentUsageMutation,
  UpdateContentUsageInput,
} from "@/content-usage/__generated__/ContentModulesDragDropProvider_UpdateContentUsageMutation.graphql"
import { useContentUsageDrawerContext } from "@/content-usage/drawer/ContentUsageDrawerContext"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useFormStore } from "@/core/form/store/FormStore"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import useDisclosure from "@utils/hook/useDisclosure"
import { TestIDProps } from "@utils/typeUtils"
import { useQueryParams } from "@utils/url/urlUtils"
import { useState } from "react"
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd"
import ConnectionHandler from "relay-connection-handler-plus"
import { commitLocalUpdate, graphql } from "relay-runtime"

export const CONTENT_USAGE_DROPPABLE_TYPE = "usages"
export const CONTENT_MODULE_DROPPABLE_TYPE = "modules"

interface ContentModulesDragDropProviderProps extends TestIDProps {
  children: JSX.Element
  entityId: GlobalID
  droppableType: string
  checkPrerequisites?: (result: DropResult) => boolean
}

function ContentModulesDragDropProvider({
  children,
  entityId,
  droppableType,
  checkPrerequisites,
}: ContentModulesDragDropProviderProps) {
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()

  const { contentUsageForm } = useContentUsageDrawerContext()
  const { filterContentLabelId } = useQueryParams<{ filterContentLabelId?: string }>()
  const { isOpen, onOpen, onClose } = useDisclosure(false)
  const [dropResult, setDropResult] = useState<DropResult | undefined>(undefined)

  const form = useFormStore<
    ContentModulesDragDropProvider_UpdateContentUsageMutation,
    UpdateContentUsageInput
  >(
    graphql`
      mutation ContentModulesDragDropProvider_UpdateContentUsageMutation(
        $input: UpdateContentUsageInput!
        $contentLabelIds: [ID!]
      ) {
        response: updateContentUsage(input: $input) {
          node {
            id
            isLocked
            entityId
            ordering
            module {
              usages {
                edges {
                  node {
                    id
                    releasedAt
                  }
                }
              }
              children {
                edges {
                  node {
                    id
                    ordering
                  }
                }
              }
            }
            ...ContentModuleUtils_RefreshContentModulesFragment
              @arguments(contentLabelIds: $contentLabelIds)
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: activeOrganization.id,
      productId: activeProduct?.id,
      // contentUsageId will be set provided in the handleDragEnd function
      contentUsageId: "",
    }
  )

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={"ContentModuleList"} type={droppableType}>
        {(droppableProvided) => (
          <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
            {children}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
      <DiscoWarningModal
        isOpen={isOpen}
        onClose={onClose}
        title={"This module has prerequisites defined."}
        description={
          "Rearranging this module may lead to conflicts with the existing prerequisites and may require reconfiguration of settings. Are you certain you want to proceed?"
        }
        modalContentLabel={"ContentModulePrerequisite.warning.modal"}
        testid={"ContentModulePrerequisite.warning.modal"}
        confirmationButtonProps={{
          onClick: () => {
            continueDragEnd()
            onClose()
          },
          children: "I understand",
        }}
      />
    </DragDropContext>
  )

  function handleDragEnd(result: DropResult) {
    const parsed = Relay.fromGlobalId(entityId)
    // Stop reordering if trying to replace the default collection module
    if (parsed.type === "Collection" && result.destination?.index === 0) {
      return
    }
    setDropResult(result)
    if (checkPrerequisites && checkPrerequisites(result)) {
      if (result.source.index === result.destination?.index) return
      onOpen()
    } else {
      continueDragEnd(result)
    }
  }

  async function continueDragEnd(draggedResult?: DropResult) {
    let result = draggedResult
    if (!result) result = dropResult
    if (!result) return
    if (result.source.index === undefined) return
    if (!result.destination) return

    switch (result.type) {
      case CONTENT_USAGE_DROPPABLE_TYPE:
        await handleContentUsageDragEnd(result)
        break
      case CONTENT_MODULE_DROPPABLE_TYPE:
        await handleContentModuleDragEnd(result)
        break
    }
    setDropResult(undefined)
  }

  async function handleContentUsageDragEnd(result: DropResult) {
    const wasPreviewDragged = result.draggableId === "preview-item"

    if (!result.destination) return
    const { destination } = result

    // If the item being dragged is the preview item, we update its ordering and entityId
    if (
      wasPreviewDragged &&
      contentUsageForm &&
      contentUsageForm.state.contentUsageInput
    ) {
      contentUsageForm.state.contentUsageInput.ordering = destination.index
      contentUsageForm.state.contentUsageInput.entityId = destination.droppableId
      return
    }

    // Get the indexes that take the preview item into account and moves the preview if necessary
    const adjustedIndexes = getIndexesAdjustedForPreviewItem(result)

    // If item was dragged within its module
    if (result.source.droppableId === destination.droppableId) {
      // If item dropped back into where it was dragged from, we do nothing
      if (result.source.index === destination.index) return

      // Update the local Relay store with the new orderings
      commitLocalUpdate(RelayEnvironment, (store) => {
        const connection = store
          .get(result.source.droppableId)
          ?.getLinkedRecord("children")

        if (!connection) return

        Relay.reorderEdgeInConnection(
          connection,
          adjustedIndexes.sourceIndex,
          adjustedIndexes.destinationIndex
        )

        const moduleRecord = store.get(result.source.droppableId)
        if (!moduleRecord) return

        ConnectionHandler.getConnections(
          moduleRecord,
          "ContentUsageGrid__children"
        ).forEach((c) => {
          Relay.reorderEdgeInPaginatedConnection(
            store,
            c,
            result.draggableId,
            adjustedIndexes.destinationIndex
          )
        })
      })

      // Reorder the content usages in the backend
      await form.submit(
        {
          ...form.state,
          contentUsageId: result.draggableId,
          contentUsageInput: {
            ordering: adjustedIndexes.destinationIndex,
          },
        },
        {
          variables: {
            contentLabelIds: filterContentLabelId ? [filterContentLabelId] : undefined,
          },
        }
      )

      commitLocalUpdate(RelayEnvironment, (store) => {
        // invalidate all collection folder connections if adding content
        ContentModuleUtils.invalidateCollectionFolderConnections(
          store,
          contentUsageForm?.state.contentUsageInput?.entity,
          contentUsageForm?.state.contentUsageInput?.entityId
        )
      })

      return
    }

    // If item was dragged to a different module, we move it to that module
    if (result.source.droppableId !== destination.droppableId) {
      commitLocalUpdate(RelayEnvironment, (store) => {
        const sourceModuleRecord = store.get(result.source.droppableId)
        const destinationModuleRecord = store.get(destination.droppableId)

        if (!sourceModuleRecord || !destinationModuleRecord) return

        /* Move the item between non-paginated children connections */
        const sourceConnection = sourceModuleRecord.getLinkedRecord("children")
        const destinationConnection = destinationModuleRecord.getLinkedRecord("children")

        if (!sourceConnection || !destinationConnection) return

        // Move the item between the source module and the destination module
        Relay.moveNodeBetweenConnections(
          sourceConnection,
          destinationConnection,
          result.draggableId,
          adjustedIndexes.destinationIndex
        )

        /*  Move the item between paginated children connection in ContentUsageGrid */
        const sourceModuleConnection = ConnectionHandler.getConnection(
          sourceModuleRecord,
          "ContentUsageGrid__children"
        )

        const destinationModuleConnection = ConnectionHandler.getConnection(
          destinationModuleRecord,
          "ContentUsageGrid__children"
        )

        // If the destination module has not been loaded yet, we delete the node from the source module
        if (!destinationModuleConnection && sourceModuleConnection) {
          Relay.deleteNodeFromConnection(sourceModuleConnection, result.draggableId)
        }

        // If the source module has not been loaded yet, we insert the node into the destination module
        if (!sourceModuleConnection && destinationModuleConnection) {
          Relay.insertNodeIntoPaginatedConnection(
            store,
            destinationModuleConnection,
            store.get(result.draggableId)!,
            adjustedIndexes.destinationIndex
          )
          return
        }

        if (!sourceModuleConnection || !destinationModuleConnection) return

        // If both connections are loaded then move the item between the source module and the destination module
        Relay.moveNodeBetweenPaginatedConnections(
          store,
          sourceModuleConnection,
          destinationModuleConnection,
          result.draggableId,
          adjustedIndexes.destinationIndex
        )
      })

      // Reorder the content usages in the backend
      await form.submit(
        {
          ...form.state,
          contentUsageId: result.draggableId,
          contentUsageInput: {
            entityId: destination.droppableId,
            ordering: adjustedIndexes.destinationIndex,
            prerequisiteIds: [], // Moving to a different module remove prerequisites since we only allow adding items from the same module as prerequisites to each other
          },
        },
        {
          variables: {
            contentLabelIds: filterContentLabelId ? [filterContentLabelId] : undefined,
          },
        }
      )

      commitLocalUpdate(RelayEnvironment, (store) => {
        ContentModuleUtils.invalidateCollectionFolderConnections(
          store,
          "content",
          result.source.droppableId
        )
        ContentModuleUtils.invalidateCollectionFolderConnections(
          store,
          "content",
          destination.droppableId
        )
      })
    }
  }

  // Takes into account the preview item index when calculating the destination index
  function getIndexesAdjustedForPreviewItem(result: DropResult) {
    const destination = result.destination!

    if (
      // If we are not creating a new content usage, there will be no preview item
      contentUsageForm?.state.mode !== "add" ||
      // If the preview item is not in the destination module, we don't need to adjust the indexes
      contentUsageForm?.state.contentUsageInput?.entityId !== destination.droppableId ||
      // Check that the ordering is not null or undefined
      contentUsageForm?.state.contentUsageInput?.ordering === undefined ||
      contentUsageForm?.state.contentUsageInput?.ordering === null
    ) {
      // No preview item in the destination module
      return {
        destinationIndex: destination.index,
        sourceIndex: result.source.index,
      }
    }

    const previewItemOrdering = contentUsageForm.state.contentUsageInput.ordering

    if (destination.index <= previewItemOrdering) {
      // If an item came from below the preview item, we move the preview item down
      if (result.source.index > previewItemOrdering) {
        contentUsageForm.state.contentUsageInput.ordering = previewItemOrdering + 1
        // Source is below preview, destination is above preview
        return {
          destinationIndex: destination.index,
          sourceIndex: result.source.index - 1,
        }
      }

      if (destination.index === previewItemOrdering) {
        contentUsageForm.state.contentUsageInput.ordering = previewItemOrdering - 1
        // Source is above preview, destination is directly below preview
        return {
          destinationIndex: destination.index - 1,
          sourceIndex: result.source.index,
        }
      }

      // Both items are above preview item
      return {
        destinationIndex: destination.index,
        sourceIndex: result.source.index,
      }
    }

    // If an item came from above the preview item, we move the preview item up
    if (result.source.index < previewItemOrdering) {
      contentUsageForm.state.contentUsageInput.ordering = previewItemOrdering - 1
    }

    if (result.source.index > previewItemOrdering) {
      // Both items are below preview item
      return {
        destinationIndex: destination.index - 1,
        sourceIndex: result.source.index - 1,
      }
    }

    // Source is above preview, destination is below preview
    return {
      destinationIndex: destination.index - 1,
      sourceIndex: result.source.index,
    }
  }

  async function handleContentModuleDragEnd(result: DropResult) {
    // No request if order is not changed
    if (!result.destination || result.source.index === result.destination.index) return
    const { destination } = result

    await form.submit(
      // Submit new ordering to backend
      {
        ...form.state,
        contentUsageId: result.draggableId,
        contentUsageInput: {
          ordering: destination.index,
        },
      },
      // Immediately update UI to reflect new ordering.
      {
        variables: {
          contentLabelIds: filterContentLabelId ? [filterContentLabelId] : undefined,
        },
        optimisticUpdater: (store) => {
          const connection = store.get(entityId)?.getLinkedRecord("modules")
          if (!connection) return
          Relay.reorderEdgeInConnection(
            connection,
            result.source.index,
            destination.index
          )
        },
        updater: (store) => {
          const parentEntity = store.get(entityId)
          if (!parentEntity) return

          ConnectionHandler.getConnections(
            parentEntity,
            "CollectionModuleList__modules"
          ).forEach((c) => {
            Relay.reorderEdgeInPaginatedConnection(
              store,
              c,
              result.draggableId,
              destination.index
            )
          })
        },
      }
    )
  }
}

export default ContentModulesDragDropProvider
