import { EditSlackInviteContentButtonModal_ContentUsageFragment$key } from "@/content-usage/buttons/__generated__/EditSlackInviteContentButtonModal_ContentUsageFragment.graphql"
import { EditSlackInviteContentButton_ContentUsageFragment$key } from "@/content-usage/buttons/__generated__/EditSlackInviteContentButton_ContentUsageFragment.graphql"
import { EditSlackInviteContentButton_UpdateContentUsageMutation } from "@/content-usage/buttons/__generated__/EditSlackInviteContentButton_UpdateContentUsageMutation.graphql"
import { InlineContentDrawerFormState } from "@/content-usage/drawer/InlineContentDrawerTemplate"
import ContentFormThumbnailSection from "@/content/form/sections/ContentFormThumbnailSection"
import ContentFormTitleSection from "@/content/form/sections/ContentFormTitleSection"
import ContentUtils from "@/content/util/contentUtils"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useFormStore } from "@/core/form/store/FormStore"
import Form from "@components/form/Form"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoModal } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import { TestIDProps } from "@utils/typeUtils"
import { rest } from "lodash"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface EditSlackInviteContentModalProps extends TestIDProps {
  onClose: () => void
  contentUsageKey: EditSlackInviteContentButtonModal_ContentUsageFragment$key
}

function EditSlackInviteContentModal({
  onClose,
  contentUsageKey,
}: EditSlackInviteContentModalProps) {
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()!

  const contentUsage =
    useFragment<EditSlackInviteContentButtonModal_ContentUsageFragment$key>(
      graphql`
        fragment EditSlackInviteContentButtonModal_ContentUsageFragment on ContentUsage {
          id
          entity
          content {
            id
            type
            label
            name
            thumbnailUrl
          }
        }
      `,
      contentUsageKey
    )

  const contentLabel = ContentUtils.useContentLabel({
    content: contentUsage.content,
    entity: contentUsage.entity,
  })
  const form = useFormStore<
    EditSlackInviteContentButton_UpdateContentUsageMutation,
    InlineContentDrawerFormState
  >(
    graphql`
      mutation EditSlackInviteContentButton_UpdateContentUsageMutation(
        $input: UpdateContentUsageInput!
      ) {
        response: updateContentUsage(input: $input) {
          node {
            id
            ...ContentUsageListItemFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: activeOrganization.id,
      productId: activeProduct.id,
      contentUsageId: contentUsage.id,
      content: {
        name: contentUsage.content.name,
        thumbnailUrl: contentUsage.content.thumbnailUrl,
      },
      mode: "edit",
    }
  )

  return (
    <DiscoModal
      isOpen
      title={`Edit ${contentLabel}`}
      testid={"EditSlackInviteButton.modal"}
      onClose={onClose}
      modalContentLabel={"Edit Slack Invite"}
      buttons
      body={
        <Form
          id={"EditSlackInviteButtonForm"}
          onSubmit={handleSubmit}
          testid={"EditSlackInviteButton.form"}
          buttons={
            <>
              <DiscoButton color={"grey"} variant={"outlined"} onClick={onClose}>
                {"Cancel"}
              </DiscoButton>
              <Form.SubmitButton
                form={form}
                id={"EditSlackInviteButtonForm"}
                testid={"EditSlackInviteButtonForm.submit"}
              >
                {"Save Changes"}
              </Form.SubmitButton>
            </>
          }
        >
          <ContentFormTitleSection form={form} label={`Title`} />
          <ContentFormThumbnailSection
            form={form}
            label={"Thumbnail"}
            showPlayIcon={false}
          />
        </Form>
      }
    />
  )

  async function handleSubmit() {
    try {
      const { didSave } = await form.submit({
        organizationId: activeOrganization.id,
        productId: activeProduct.id,
        contentUsageId: contentUsage.id,
        content: form.state.content,
      })

      if (!didSave) return

      displaySuccessToast({
        message: `${contentLabel} updated!`,
      })

      onClose()
    } catch (error) {
      displayErrorToast(error)
    }
  }
}

interface EditSlackInviteContentButtonProps extends OverridableDiscoButtonProps {
  contentUsageKey: EditSlackInviteContentButton_ContentUsageFragment$key
}

function EditSlackInviteContentButton({
  contentUsageKey,
  children,
}: EditSlackInviteContentButtonProps) {
  const contentUsage = useFragment<EditSlackInviteContentButton_ContentUsageFragment$key>(
    graphql`
      fragment EditSlackInviteContentButton_ContentUsageFragment on ContentUsage {
        id
        ...EditSlackInviteContentButtonModal_ContentUsageFragment
      }
    `,
    contentUsageKey
  )

  return (
    <OverridableDiscoButton
      {...rest}
      modal={({ isOpen, onClose }) => {
        // Reset form state on each open
        if (!isOpen) return null
        return (
          <EditSlackInviteContentModal onClose={onClose} contentUsageKey={contentUsage} />
        )
      }}
    >
      {children}
    </OverridableDiscoButton>
  )
}

export default EditSlackInviteContentButton
