/**
 * @generated SignedSource<<a10545ff0c513eab66e040a8c75e84d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentUsageMarkAsCompleteButtonFragment$data = {
  readonly id: string;
  readonly confirmationModalContent: {
    readonly " $fragmentSpreads": FragmentRefs<"CurriculumConfirmationModalConfirmationModalFragment">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"useTrackContentCompletionFragment" | "usePermissionsFragment">;
  readonly " $fragmentType": "ContentUsageMarkAsCompleteButtonFragment";
};
export type ContentUsageMarkAsCompleteButtonFragment$key = {
  readonly " $data"?: ContentUsageMarkAsCompleteButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageMarkAsCompleteButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentUsageMarkAsCompleteButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTrackContentCompletionFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "confirmationModalContent",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CurriculumConfirmationModalConfirmationModalFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "47eec42665eec9621534dcef3504209d";

export default node;
