/**
 * @generated SignedSource<<1de6b2fcacbf34884198a3ab9d7721eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditSlackInviteContentButton_ContentUsageFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"EditSlackInviteContentButtonModal_ContentUsageFragment">;
  readonly " $fragmentType": "EditSlackInviteContentButton_ContentUsageFragment";
};
export type EditSlackInviteContentButton_ContentUsageFragment$key = {
  readonly " $data"?: EditSlackInviteContentButton_ContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditSlackInviteContentButton_ContentUsageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditSlackInviteContentButton_ContentUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditSlackInviteContentButtonModal_ContentUsageFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "79de4b94180a587169ba31d4ecea42ed";

export default node;
