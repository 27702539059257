import {
  CloneContentModuleButtonMutation,
  CloneContentModuleInput,
} from "@/content-usage/buttons/__generated__/CloneContentModuleButtonMutation.graphql"
import { CloneContentModuleButtonQuery } from "@/content-usage/buttons/__generated__/CloneContentModuleButtonQuery.graphql"
import CloneContentModuleContentCloneMethodFormFields from "@/content-usage/forms/CloneContentModuleContentCloneMethodFormFields"
import CloneContentModuleContentDueDatesFormFields from "@/content-usage/forms/CloneContentModuleContentDueDatesFormFields"
import CloneContentModuleFormFields from "@/content-usage/forms/CloneContentModuleFormFields"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import useTrackCloneJobStarted from "@/product/clone/hooks/useTrackCloneJobStarted"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoButtonSkeleton, DiscoModal } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import usePermissions from "@utils/hook/usePermissions"
import { useQueryParams } from "@utils/url/urlUtils"
import { addDays } from "date-fns"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface CloneContentModuleButtonProps {
  contentModuleUsageId: GlobalID
  children: OverridableDiscoButtonChildren
}

type CloneContentModuleStep = "module" | "content_clone_method" | "item_due_dates"
export type CloneContentModuleDestinationTab = "within_entity" | "across_experiences"

export type CloneContentModuleFormState = CloneContentModuleInput & {
  destinationCurriculum: {
    id: GlobalID
  } | null
  destinationCollection: {
    id: GlobalID
  } | null
  destinationProduct: {
    id: GlobalID
    startDate: Date | null
  } | null
  destinationTab: CloneContentModuleDestinationTab
}

function CloneContentModuleButton({
  contentModuleUsageId,
  children,
}: CloneContentModuleButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const trackCloneJobStarted = useTrackCloneJobStarted()
  const isMobile = useIsMobile()
  const activeOrganization = useActiveOrganization()!
  const { filterContentLabelId } = useQueryParams<{ filterContentLabelId?: string }>()

  const { organization, contentModuleUsage } =
    useLazyLoadQuery<CloneContentModuleButtonQuery>(
      graphql`
        query CloneContentModuleButtonQuery(
          $contentModuleUsageId: ID!
          $organizationId: ID!
        ) {
          organization: node(id: $organizationId) {
            id
            __typename
            ... on Organization {
              id
              ...CloneContentModuleFormFieldsFragment
            }
          }
          contentModuleUsage: node(id: $contentModuleUsageId) {
            id
            __typename
            ... on ContentUsage {
              id
              entity
              releasedAt
              product {
                id
                startDate
                ...usePermissionsFragment
              }
              curriculum {
                id
              }
              collection {
                id
              }
              content {
                id
                name
                contentUsages: children {
                  # For the content module -> get all content_usages with a due date
                  edges {
                    node {
                      id
                      dueAt
                      content {
                        name
                      }
                    }
                  }
                }
              }
              ...CloneContentModuleContentDueDatesFormFieldsFragment
            }
          }
        }
      `,
      {
        contentModuleUsageId,
        organizationId: activeOrganization.id,
      },
      {
        fetchPolicy: "network-only",
      }
    )

  const isCurriculum = contentModuleUsage?.entity === "curriculum"

  const form = useFormStore<
    CloneContentModuleButtonMutation,
    CloneContentModuleFormState
  >(
    graphql`
      mutation CloneContentModuleButtonMutation(
        $input: CloneContentModuleInput!
        $contentLabelIds: [ID!]
      ) {
        response: cloneContentModule(input: $input) {
          node {
            id
            destinationProduct {
              id
              curriculum {
                id
                ...CurriculumModuleListFragment
                  @arguments(contentLabelIds: $contentLabelIds)
              }
            }
            destinationContentUsage {
              id
              ...ContentModuleNavigationSectionFragment
              collection {
                ...CollectionModuleList_PaginationFragment @arguments(first: 6)
              }
            }
            status
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      name: `${contentModuleUsage?.content?.name} - Copy`,
      releasedAt: isCurriculum
        ? contentModuleUsage?.releasedAt || addDays(new Date(), 7).toISOString()
        : null,
      destinationEntityId:
        contentModuleUsage?.curriculum?.id || contentModuleUsage?.collection?.id || "",
      contentModuleUsageId: contentModuleUsage?.id || "",
      destinationCurriculum: contentModuleUsage?.curriculum
        ? {
            id: contentModuleUsage.curriculum.id,
          }
        : null,
      destinationCollection: contentModuleUsage?.collection
        ? {
            id: contentModuleUsage.collection.id,
          }
        : null,
      destinationProduct: {
        id: contentModuleUsage?.product?.id || "",
        startDate: contentModuleUsage?.product?.startDate
          ? new Date(contentModuleUsage.product.startDate)
          : null,
      },
      contentUsages: [],
      destinationTab: "within_entity",
      contentCloneMethod: "duplicate",
    }
  )

  const possibleSteps = getPossibleSteps()

  const [step, setStep] = useState<CloneContentModuleStep>("module")
  const permissions = usePermissions(contentModuleUsage?.product)

  if (!Relay.isNodeType(contentModuleUsage, "ContentUsage")) return null
  if (!Relay.isNodeType(organization, "Organization")) return null
  if (!permissions.has("content.manage")) return null

  return (
    <>
      <OverridableDiscoButton onClick={handleOpen}>{children}</OverridableDiscoButton>

      <DiscoModal
        isOpen={isModalOpen}
        onClose={handleClose}
        testid={"CloneContentModuleButton.modal"}
        title={"Clone Module"}
        modalContentLabel={"Clone Module"}
        maxWidth={isMobile ? "350px" : "480px"}
        buttons
        body={
          <Form
            id={"CloneContentModuleForm"}
            testid={"CloneContentModuleForm"}
            buttons={
              <>
                <DiscoButton
                  testid={"CloneContentModuleModal.back-or-cancel-button"}
                  color={"grey"}
                  variant={"outlined"}
                  onClick={handleBackOrCancel}
                >
                  {getPreviousStep() ? "Back" : "Cancel"}
                </DiscoButton>
                <DiscoButton
                  testid={"CloneContentModuleModal.next-or-submit-button"}
                  onClick={handleNextOrSubmit}
                  disabled={!form.state.destinationEntityId || form.isSubmitting}
                >
                  {getNextStep() ? "Next" : "Clone"}
                </DiscoButton>
              </>
            }
          >
            {step === "module" ? (
              <CloneContentModuleFormFields
                moduleUsageEntity={contentModuleUsage.entity!}
                data-testid={"CloneContentModuleForm"}
                organizationKey={organization}
                form={form}
              />
            ) : step === "content_clone_method" ? (
              <CloneContentModuleContentCloneMethodFormFields form={form} />
            ) : step === "item_due_dates" ? (
              <CloneContentModuleContentDueDatesFormFields
                contentModuleUsageKey={contentModuleUsage}
                form={form}
              />
            ) : null}
          </Form>
        }
      />
    </>
  )

  function getPossibleSteps() {
    const steps: CloneContentModuleStep[] = ["module"]

    const contentUsages = Relay.connectionToArray(
      contentModuleUsage?.content?.contentUsages
    )

    if (contentUsages.length > 0) {
      steps.push("content_clone_method")
    }

    const contentUsagesWithDueDate = contentUsages.filter((cu) => Boolean(cu.dueAt))
    if (contentUsagesWithDueDate.length > 0) {
      steps.push("item_due_dates")
    }

    return steps
  }

  function getNextStep(): CloneContentModuleStep | undefined {
    const currentStepIndex = possibleSteps.indexOf(step)
    return possibleSteps[currentStepIndex + 1]
  }

  function getPreviousStep(): CloneContentModuleStep | undefined {
    const currentStepIndex = possibleSteps.indexOf(step)
    return possibleSteps[currentStepIndex - 1]
  }

  function handleBackOrCancel() {
    const prevStep = getPreviousStep()

    if (prevStep) {
      setStep(prevStep)
    } else {
      handleClose()
    }
  }

  async function handleNextOrSubmit() {
    const nextStep = getNextStep()
    if (nextStep) return setStep(nextStep)
    // Only show clone job snackbar if cloning to a different product
    const shouldTrackCloneJob = form.changedState.destinationProduct?.id

    // Submit the form
    const { didSave, response } = await form.submit(
      {
        name: form.state.name,
        releasedAt: form.state.releasedAt,
        destinationEntityId:
          form.state.destinationCurriculum?.id ||
          form.state.destinationCollection?.id ||
          "",
        contentModuleUsageId: form.state.contentModuleUsageId,
        contentUsages: form.state.contentUsages,
        contentCloneMethod: form.state.contentCloneMethod,
      },
      {
        variables: {
          contentLabelIds: filterContentLabelId ? [filterContentLabelId] : undefined,
        },
      }
    )
    if (!didSave || !response?.node) return

    if (shouldTrackCloneJob) {
      trackCloneJobStarted(response.node.id)
    } else {
      displaySuccessToast({
        testid: `CreateContentModuleForm.created.emoji`,
        message: "Module cloned",
      })
    }
    handleClose()
  }

  function resetState() {
    form.reset()
    setStep("module")
  }

  function handleOpen() {
    setIsModalOpen(true)
  }

  function handleClose() {
    setIsModalOpen(false)
    resetState()
  }
}

export default Relay.withSkeleton<CloneContentModuleButtonProps>({
  component: observer(CloneContentModuleButton),
  skeleton: () => <DiscoButtonSkeleton />,
})
