import Form from "@/components/form/Form"
import ContentUsageFormEventsField from "@/content-usage/buttons/events/ContentUsageFormEventsField"
import { ContentUsageAssociateEventsButtonFragment$key } from "@/content-usage/buttons/events/__generated__/ContentUsageAssociateEventsButtonFragment.graphql"
import {
  ContentUsageAssociateEventsButtonMutation,
  UpdateContentUsageInput,
} from "@/content-usage/buttons/events/__generated__/ContentUsageAssociateEventsButtonMutation.graphql"
import { useFormStore } from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoModal, DiscoText } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { IObservableArray } from "mobx"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { graphql, useFragment } from "react-relay"

type Props = {
  contentUsageKey: ContentUsageAssociateEventsButtonFragment$key
  children: OverridableDiscoButtonChildren
}

function ContentUsageAssociateEventsButton(props: Props) {
  const { contentUsageKey, children } = props
  const [isModalOpen, setIsModalOpen] = useState(false)

  const contentUsage = useFragment<ContentUsageAssociateEventsButtonFragment$key>(
    graphql`
      fragment ContentUsageAssociateEventsButtonFragment on ContentUsage {
        id
        productId
        organizationId
        occurrences {
          edges {
            node {
              id
            }
          }
        }
      }
    `,
    contentUsageKey
  )
  const occurrenceIds = Relay.connectionToArray(contentUsage.occurrences).map((o) => o.id)

  const form = useFormStore<
    ContentUsageAssociateEventsButtonMutation,
    UpdateContentUsageInput
  >(
    graphql`
      mutation ContentUsageAssociateEventsButtonMutation(
        $input: UpdateContentUsageInput!
      ) {
        response: updateContentUsage(input: $input) {
          node {
            id
            ...ContentUsageAssociateEventsButtonFragment
            ...ContentModuleListItemFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: contentUsage.organizationId,
      productId: contentUsage.productId,
      contentUsageId: contentUsage?.id || "",
      contentUsageInput: {
        occurrences: occurrenceIds,
      },
    }
  )

  return (
    <>
      <OverridableDiscoButton onClick={() => setIsModalOpen(true)}>
        {children}
      </OverridableDiscoButton>

      <DiscoModal
        isOpen={isModalOpen}
        onClose={handleClose}
        testid={"ContentUsageAssociateEventsButton.modal"}
        title={"Associate/Link Events"}
        subtitle={
          "Drive more registrations and higher attendance by associating events with this Module."
        }
        modalContentLabel={"Associate Events With Module"}
        width={"635px"}
        buttons
        body={
          <Form
            id={"ContentUsageAssociateEventsButtonForm"}
            onSubmit={handleSave}
            buttons={
              <>
                <DiscoButton color={"grey"} variant={"outlined"} onClick={handleClose}>
                  {"Cancel"}
                </DiscoButton>
                <Form.SubmitButton
                  id={"ContentUsageAssociateEventsButtonForm"}
                  testid={"ContentUsageAssociateEventsButton.submit"}
                  form={form}
                >
                  {"Save Associations"}
                </Form.SubmitButton>
              </>
            }
          >
            <DiscoText variant={"body-sm-700"} marginTop={3}>
              {"Select Event"}
            </DiscoText>
            <ContentUsageFormEventsField form={form} showEventsContainer />
          </Form>
        }
      />
    </>
  )

  function handleClose() {
    ;(form.state.contentUsageInput?.occurrences as IObservableArray)?.replace(
      occurrenceIds
    )
    setIsModalOpen(false)
  }

  async function handleSave() {
    const { didSave } = await form.submit(form.state)
    if (!didSave) return
    setIsModalOpen(false)
    displaySuccessToast({
      message: "Event associations saved!",
      testid: "ContentUsageAssociateEventsButton.success-toast",
    })
  }
}

export default observer(ContentUsageAssociateEventsButton)
