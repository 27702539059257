import AddContentUsageButton from "@/content-usage/buttons/add-button/AddContentUsageButton"
import { CreateCurriculumModuleItemContentUsageButtonFragment$key } from "@/content-usage/buttons/add-button/__generated__/CreateCurriculumModuleItemContentUsageButtonFragment.graphql"
import { OverridableDiscoButtonProps } from "@disco-ui/button/OverridableDiscoButton"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type Props = OverridableDiscoButtonProps &
  TestIDProps & {
    moduleContentUsageKey: CreateCurriculumModuleItemContentUsageButtonFragment$key
  }

function CreateCurriculumModuleItemContentUsageButton({
  testid = "CreateCurriculumModuleItemContentUsageButton",
  children,
  moduleContentUsageKey,
}: Props) {
  const moduleContentUsage =
    useFragment<CreateCurriculumModuleItemContentUsageButtonFragment$key>(
      graphql`
        fragment CreateCurriculumModuleItemContentUsageButtonFragment on ContentUsage {
          id
          entity
          entityId
          content {
            id
            createCurriculumModuleItemContentUsageButtonChildren: children {
              totalCount
            }
          }
          productId
        }
      `,
      moduleContentUsageKey
    )

  return (
    <AddContentUsageButton
      testid={testid}
      productId={moduleContentUsage.productId}
      contentUsageInput={{
        entity: "content",
        entityId: moduleContentUsage.content.id,
        ordering:
          moduleContentUsage.content.createCurriculumModuleItemContentUsageButtonChildren!
            .totalCount, // Always add to the end of the curriculum module
      }}
      addingTo={"curriculum"}
      moduleContentUsageId={moduleContentUsage.id}
    >
      {children}
    </AddContentUsageButton>
  )
}

export default CreateCurriculumModuleItemContentUsageButton
