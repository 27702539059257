import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoIcon, DiscoIconButton, DiscoSection, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"

interface ContentUsagePrerequisiteListSectionProps extends TestIDProps {
  body: React.ReactNode
  title: string | React.ReactNode
  subTitle: string | React.ReactNode
  rightIconButton?: React.ReactNode
}

export default function ContentUsagePrerequisiteListSection({
  body,
  title,
  subTitle,
  rightIconButton,
  testid = "ContentUsagePrerequisiteListSection",
}: ContentUsagePrerequisiteListSectionProps) {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const classes = useStyles({ isCollapsed })
  return (
    <DiscoSection testid={testid} className={classes.prerequisiteList}>
      <div className={classes.prerequisiteListTitle}>
        <div className={classes.titleRhs}>
          <DiscoIconButton
            testid={`${testid}.icon.${isCollapsed ? "collapsed" : "expanded"}`}
            size={"small"}
            variant={"outlined"}
            onClick={() => setIsCollapsed((prevState) => !prevState)}
            className={classes.expandButton}
          >
            <DiscoIcon icon={"chevron"} />
          </DiscoIconButton>
          <div>
            {typeof title === "string" ? (
              <DiscoText variant={"body-md-600"}>{title}</DiscoText>
            ) : (
              title
            )}
            {renderSubTitle()}
          </div>
        </div>
        {rightIconButton}
      </div>
      <div className={classes.body}>{!isCollapsed && body}</div>
    </DiscoSection>
  )

  function renderSubTitle() {
    if (isCollapsed) return null
    return typeof subTitle === "string" ? (
      <DiscoText variant={"body-md-600"} marginBottom={1}>
        {subTitle}
      </DiscoText>
    ) : (
      subTitle
    )
  }
}

interface StyleProps {
  isCollapsed: boolean
}

const useStyles = makeUseStyles((theme) => ({
  titleRhs: ({ isCollapsed }: StyleProps) => ({
    display: "flex",
    alignItems: isCollapsed ? "center" : "flex-start",
    gap: theme.spacing(1),
  }),
  prerequisiteListTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  prerequisiteList: {
    boxShadow: theme.palette.groovyDepths.insideCard,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  expandButton: ({ isCollapsed }: StyleProps) => ({
    transform: "rotate(180deg)",
    "& path": {
      color: theme.palette.text.secondary,
    },
    ...styleIf(isCollapsed, {
      transform: "rotate(90deg)",
    }),
  }),
}))
