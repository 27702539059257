import {
  ContentUsageEntity,
  ContentUsageInput,
} from "@/content-usage/__generated__/ContentModulesDragDropProvider_UpdateContentUsageMutation.graphql"
import AddContentUsageContentOptions, {
  SelectedTab,
} from "@/content-usage/buttons/add-button/AddContentUsageContentOptions"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import { CONTENT_TEMPLATES, ContentTemplateKind } from "@/content/util/contentTemplates"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButtonSkeleton, DiscoModal } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useDisclosure from "@utils/hook/useDisclosure"
import { useState } from "react"
import { v4 as uuidv4 } from "uuid"

export interface AddContentUsageButtonProps extends OverridableDiscoButtonProps {
  testid: string
  children: OverridableDiscoButtonChildren
  productId?: GlobalID | null
  contentUsageInput: ContentUsageInput
  addingTo: ContentUsageEntity
  moduleContentUsageId?: GlobalID | null
}

function AddContentUsageButton({
  children,
  testid,
  productId,
  contentUsageInput,
  addingTo,
  moduleContentUsageId,
  disabled,
}: AddContentUsageButtonProps) {
  const drawer = useContentUsageDrawer()
  const isMobile = useIsMobile()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [selectedTab, setSelectedTab] = useState<SelectedTab>("new")
  const classes = useStyles()
  const { handleLeave } = useUnsavedChangesModalContext()

  return (
    <>
      <OverridableDiscoButton
        className={classes.addContentUsageButton}
        onClick={openModal}
        leftIcon={"add"}
        data-testid={testid}
        disabled={disabled}
      >
        {children}
      </OverridableDiscoButton>

      {isOpen && (
        <DiscoModal
          title={"Add Content"}
          modalContentLabel={"Add Content"}
          testid={"AddContentModal"}
          isOpen={isOpen}
          onClose={onClose}
          width={"720px"}
          maxWidth={"95vw"}
          maxHeight={"95vh"}
          portalClassName={isMobile ? classes.modalPortal : undefined}
          body={
            <AddContentUsageContentOptions
              productId={productId}
              addingTo={addingTo}
              onSelect={onClose}
              contentUsageInput={contentUsageInput}
              moduleContentUsageId={moduleContentUsageId!}
              onTemplateSelect={onTemplateSelect}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          }
        />
      )}
    </>
  )

  // This modal can be opened from a drawer where they may be unsaved changes
  function openModal() {
    handleLeave({
      onLeave: onOpen,
    })
  }

  function onTemplateSelect(template: ContentTemplateKind) {
    const { contentType, systemTaskKind } = CONTENT_TEMPLATES[template]

    if (template === "ai") {
      setSelectedTab("generate")
      return
    }

    drawer.open({
      drawerContentType: contentType,
      drawerUsageEntity: contentUsageInput.entity!,
      drawerUsageEntityId: contentUsageInput.entityId!,
      contentTemplate: template,
      // Generate a new uuid for the ContentUsage so we do not have to navigate
      // to a new drawer after creation
      drawerContentUsageId: Relay.toGlobalId("ContentUsage", uuidv4()),
      drawerModuleContentUsageId: moduleContentUsageId!,
      ...(systemTaskKind
        ? {
            drawerContentSystemTaskKind: systemTaskKind,
          }
        : {}),
    })

    onClose()
  }
}

const useStyles = makeUseStyles((theme) => ({
  addContentUsageButton: {
    padding: theme.spacing(1, 1.5, 1, 1.5),
    height: "40px",
    borderRadius: theme.measure.borderRadius.medium,
  },
  modalPortal: {
    // Since this modal can be opened from a higher zIndex
    // drawer, always need to make sure it's on top
    zIndex: theme.zIndex.modal + 1,
  },
}))

export default Relay.withSkeleton<AddContentUsageButtonProps>({
  component: AddContentUsageButton,
  skeleton: DiscoButtonSkeleton,
})
